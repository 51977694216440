import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  DateInterval,
  ErrorObject,
  Reservation,
} from 'src/shared/data-types/types';
import Text from 'components/shared/text';
import { css } from 'styled-components';
import theme from 'src/shared/theme';
import Button from 'components/shared/button';
import bonzError from 'src/shared/utils/bonz-error';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';
import ReservationNumberInput from './reservation-number-input';
import ReservationDeleted from './reservation-deleted';
import Error from '../../shared/error';

interface Props {
  className?: string;
  pageContent: {
    title: string;
    description: string;
    wrongCodeMessage: string;
    cancelButton: string;
    inputPlaceholder: string;
  };
  initialCode?: string;
}

// TODO: rewrite to use react form hook
const ReservationDeleter: FC<Props> = ({ pageContent, initialCode = `` }) => {
  const isMountedRef = useIsMountedRef();

  const [error, setError] = useState<ErrorObject | null>();
  const [reservationCode, setReservationCode] = useState(initialCode);
  const [reservationTime, setReservationTime] = useState(
    null as DateInterval | null
  );
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(
    pageContent.cancelButton
  );
  const [codeValidity, setCodeValidity] = useState({
    isValid: false,
    invalidMessage: ``,
  });
  const [checkRequiredCondition, setCheckRequiredCondition] = useState(false);

  useEffect(() => {
    if (reservationCode.trim() === ``) {
      if (checkRequiredCondition) {
        setCodeValidity({
          isValid: false,
          invalidMessage: `Zadejte prosím kód rezervace.`,
        });
      } else {
        setCodeValidity({
          isValid: false,
          invalidMessage: ``,
        });
      }
      return;
    }

    const alphanumericWithAtAndQuestionMark = /^([a-zA-Z0-9\u003F-\u0040 _.-]+)$/;
    const isValid = alphanumericWithAtAndQuestionMark.test(reservationCode);

    if (isValid) {
      setCodeValidity({ isValid: true, invalidMessage: `` });
    } else {
      setCodeValidity({
        isValid: false,
        invalidMessage: `Kód rezervace obsahuje nepovolené znaky.`,
      });
    }
  }, [reservationCode, checkRequiredCondition]);

  function onReservationCodeChange(reservationNo) {
    setCheckRequiredCondition(true);
    setReservationCode(reservationNo);
  }

  const deleteReservation = useCallback(
    async event => {
      setSubmitButtonText(`...rušíme rezervaci`);
      event.preventDefault();

      try {
        const response = await fetch(`/.netlify/functions/delete-reservation`, {
          method: `DELETE`,
          body: JSON.stringify({ code: reservationCode }),
          headers: { 'Content-Type': `application/json` },
        });

        if (response.ok && isMountedRef?.current) {
          const reservation: Reservation = await response.json();
          setReservationTime({
            start: new Date(reservation.start),
            end: new Date(reservation.end),
          });
          setShowSuccess(true);
          setError(null);
          setReservationCode(``);
          setCheckRequiredCondition(false);
          return;
        }

        if (response.status === 404 && isMountedRef?.current) {
          setError({
            error: `Reservation not found`,
            message: pageContent.wrongCodeMessage,
          });
        }
      } catch (error) {
        bonzError(error);
        if (isMountedRef?.current) {
          setError({
            error,
            message: `Rezervaci se nepovedlo zrušit.`,
          });
        }
      } finally {
        if (isMountedRef?.current) {
          setSubmitButtonText(pageContent.cancelButton);
        }
      }
    },
    [
      isMountedRef,
      reservationCode,
      pageContent.wrongCodeMessage,
      pageContent.cancelButton,
    ]
  );

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={deleteReservation}
      data-testid="ReservationDeleter"
      css={css`
        max-width: ${theme.breakpoints.values.xs}px;
        display: flex;
        flex-direction: column;
      `}
    >
      <Text
        variant="h3"
        css={css`
          margin-bottom: ${theme.spacing(8)};
        `}
      >
        {pageContent.title}
      </Text>
      <Text
        css={css`
          margin-bottom: ${theme.spacing(8)};
        `}
      >
        {pageContent.description}
      </Text>
      <ReservationNumberInput
        onReservationCodeChange={onReservationCodeChange}
        setCheckRequiredCondition={setCheckRequiredCondition}
        code={reservationCode}
        inputPlaceholder={pageContent.inputPlaceholder}
        invalidMessage={codeValidity.invalidMessage}
        css={css`
          align-self: flex-start;
        `}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!codeValidity.isValid}
        css={css`
          margin-top: ${theme.spacing(8)};
          margin-bottom: ${theme.spacing(4)};
          align-self: flex-start;
        `}
        data-testid="submit-button"
      >
        {submitButtonText}
      </Button>
      <Error error={error} data-testid="error" />
      {showSuccess && !error ? (
        <ReservationDeleted
          reservation={reservationTime}
          reservationNo={initialCode}
        />
      ) : null}
    </form>
  );
};

export default ReservationDeleter;
