import ReservationDeleter from 'components/reservations/deleter';
import SectionContainer from 'components/shared/section-container';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'src/components/shared/layout';
import { PageContent } from 'components/reservations/shared';
import { css } from 'styled-components';
import { useLocation } from '@reach/router';

function getQueryParamByName(name: string, queryParams: string) {
  if (!queryParams) {
    return;
  }

  if (URLSearchParams) {
    const QPs = new URLSearchParams(queryParams);
    return QPs.get(name);
  }

  // IE 11
  const query = window.location.search.substring(1);
  const QPs = query.split(`&`);

  for (let i = 0; i < QPs.length; i++) {
    const [qpName, qpValue] = QPs[i].split(`=`);

    if (decodeURIComponent(qpName) === name) {
      return decodeURIComponent(qpValue);
    }
  }
}

const ReservationCancelPage = ({ data }) => {
  const location = useLocation();
  const codeFromQP = getQueryParamByName(`code`, location.search) ?? ``;

  return (
    <React.StrictMode>
      <Layout>
        <SectionContainer>
          <PageContent
            css={css`
              justify-content: center;
            `}
          >
            <ReservationDeleter
              pageContent={data.markdownRemark.frontmatter}
              initialCode={codeFromQP}
            />
          </PageContent>
        </SectionContainer>
      </Layout>
    </React.StrictMode>
  );
};

export default ReservationCancelPage;

export const pageQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: { regex: "/(cancel-reservation-page.md)/" }
    ) {
      frontmatter {
        title
        description
        wrongCodeMessage
        cancelButton
        inputPlaceholder
      }
    }
  }
`;
