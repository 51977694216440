import { DateInterval } from 'src/shared/data-types/types';
import { format } from 'date-fns';
import React, { FC } from 'react';

const ReservationDeleted: FC<{
  reservation: DateInterval | null;
  reservationNo: string;
}> = ({ reservation, reservationNo }) => (
  <div data-testid="success-form">
    {reservation ? (
      <span>
        {`Rezervace návštěvy dne ${format(reservation.start, `dd.MM.yyyy`)} od ${format(reservation.start, `H:mm`)} do ${format(reservation.end, `H:mm`)}, s číslem ${reservationNo}, byla zrušena.`}
      </span>
    ) : (
      <span>Rezervace pravděpodobně nebyla smazána.</span>
    )}
  </div>
);

export default ReservationDeleted;
