import styled, { css } from "styled-components";
import { addDays } from "date-fns";

import { SM, XS } from "src/shared/media-query";
import theme from "src/shared/theme";
import { pageSideMargin } from 'src/shared/styles/page-margin';
import { ISODate, Reservation } from "src/shared/data-types/types";
import { STATUS } from 'src/shared/data-types/enums';

export const reservationPageSidePadding = css`
  padding: ${theme.spacing(5)};

  @media ${XS}{
    padding: ${theme.spacing(10)};
  }
`;

export const PageContent = styled.div`
  ${pageSideMargin};
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(14)};

  @media ${SM} {
    flex-direction: row;
    margin-top: ${theme.spacing(14)};
    margin-bottom: ${theme.spacing(34)};
  }
`;

export const allReservedReservation: Reservation = {
  start: new Date(0).toISOString() as ISODate,
  end: addDays(new Date(), 1000).toISOString() as ISODate,
  code: `all-reserved`,
  status: STATUS.ACTIVE,
  purpose: `dfdf`
};
