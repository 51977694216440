import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  className?: string;
  code: string;
  inputPlaceholder: string;
  invalidMessage: string;
  setCheckRequiredCondition(x: boolean): void;
  onReservationCodeChange(code: string): void;
}

const ReservationNumberInput: React.FC<Props> = ({
  code,
  inputPlaceholder,
  setCheckRequiredCondition,
  onReservationCodeChange,
  invalidMessage,
  className,
}) => (
  <TextField
    id="reservation-no"
    data-testid="reservation-no"
    InputProps={{ disableUnderline: true }}
    autoFocus
    label={inputPlaceholder}
    value={code}
    variant="filled"
    onChange={event => onReservationCodeChange(event.target.value)}
    onBlur={() => setCheckRequiredCondition(true)}
    error={!!invalidMessage}
    helperText={invalidMessage}
    required
    className={className}
  />
);

export default ReservationNumberInput;
